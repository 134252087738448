import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { getThemeColor, getThemeSpacePx } from 'utils/css-utils';
import Button from 'components/Button';
import { TextLight } from 'components/Typo';

const FormLabel = styled.label`
  margin-top: ${getThemeSpacePx(3)};
  margin-bottom: ${getThemeSpacePx(1)};
  font-size: ${(p) => p.theme.fontSizesPx[1]};
  color: ${getThemeColor('text')};
`;

const Input = styled.input`
  background: transparent;
  border: none;
  display: block;
  border-bottom: 1px solid ${getThemeColor('textLight')};
  padding: ${getThemeSpacePx(2)} 0;
  font-size: ${(p) => p.theme.fontSizesPx[1]};
  color: ${getThemeColor('text')};
  border-radius: 0;

  &:focus {
    outline: none;
  }
`;

const Textarea = styled.textarea`
  padding: ${getThemeSpacePx(2)};
  margin-top: ${getThemeSpacePx(2)};
  background: ${getThemeColor('background')};
  font-size: ${(p) => p.theme.fontSizesPx[1]};
  color: ${getThemeColor('text')};
  min-height: 125px;
  resize: none;

  outline: none;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;

  ${FormLabel}:first-of-type {
    margin-top: 0;
  }

  textarea,
  label {
    display: block;
    border: none;
  }
`;

const initState = {
  success: false,
  error: false,
  name: '',
  email: '',
  message: '',
};

const encode = (data) => {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
};

export default ({ exampleText }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const [state, setState] = useState(initState);

  useEffect(() => {
    setMessage(exampleText);
  }, [exampleText]);

  const resetForm = () => {
    setName('');
    setEmail('');
    setMessage('');
    setState(initState);
  };

  const onSubmit = (evt) => {
    evt.preventDefault();

    if (!email || !message) {
      return setState({ error: 'Please fill out both fields.' });
    }

    fetch('/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: encode({
        'form-name': 'webkid-contact',
        name,
        email,
        message,
      }),
    })
      .then(() => {
        setState({ success: true, error: false });
        setTimeout(() => resetForm(), 2000);
      })
      .catch((error) => setState({ error }));
  };

  const onChange = (evt) => {
    const targetName = evt.target.name;

    switch (targetName) {
      case 'name': {
        return setName(evt.target.value);
      }
      case 'email': {
        return setEmail(evt.target.value);
      }
      case 'message': {
        return setMessage(evt.target.value);
      }
      default:
        break;
    }
  };

  return (
    <Form
      id="contact"
      data-netlify="true"
      data-netlify-honeypot="bt-field"
      onSubmit={onSubmit}
    >
      <FormLabel htmlFor="name">Your Name</FormLabel>
      <Input name="name" value={name} onChange={onChange} />
      <FormLabel htmlFor="email">Your E-Mail</FormLabel>
      <Input name="email" type="email" value={email} onChange={onChange} />
      {/* <FormLabel htmlFor="type">Type of work</FormLabel>
      <Input name="type" /> */}
      <FormLabel htmlFor="message">Your Message</FormLabel>
      <Textarea
        name="message"
        placeholder="Tell us about your project idea."
        value={message}
        onChange={onChange}
      />

      <Button icon="arrow_right_circle" style={{ width: 190, marginTop: 32 }}>
        Send message
      </Button>
      {state.error && state.error}
      {state.success && (
        <TextLight mt={1}>
          Thanks for your submission! We will reply as soon as possible.
        </TextLight>
      )}
    </Form>
  );
};
