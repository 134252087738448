import React, { useState } from 'react';
import { Box, Flex } from 'components/ReflexBox';
import styled from '@emotion/styled';

import { getThemeSpacePx } from 'utils/css-utils';
import Icon from 'components/Icon';
import { H3, Text } from 'components/Typo';
import Form from './Form';

const ContactOptions = styled(Box)`
  a:hover {
    color: ${(p) => p.theme.colors.text};
  }
`;

const ListItem = styled(Text)`
  margin-bottom: ${getThemeSpacePx(2)};
  display: flex;
  align-items: center;
  cursor: ${(p) => (p.onClick ? 'pointer' : 'default')};

  &:hover {
    opacity: ${(p) => (p.onClick ? 0.6 : 1)};
  }

  .icon {
    margin-right: ${getThemeSpacePx(2)};
    align-self: baseline;
  }
`;

const exampleTexts = [
  'Hey webkid,\nwe want to create a dashboard that visualizes our company data.',
  'Hello!\nWe would like to build an interactive map to enhance our news article.',
  'Hi webkid,\ncan you help us to extract and process data from a website.',
];

export default (props) => {
  const [exampleText, setExampleText] = useState('');

  return (
    <Flex flexWrap="wrap" justifyContent="space-between" {...props}>
      <Box width={[1, 1, 48 / 100]}>
        <Box p={[3, 3, 4]} bg="sectionBackground">
          <Form exampleText={exampleText} />
        </Box>
      </Box>
      <Flex
        width={[1, 1, 48 / 100]}
        justifyContent="spaceAround"
        alignItems="center"
      >
        <Flex p={[0, 0, 4]} flexWrap="wrap" justifyContent="spaceBetween">
          <Box width={[1, 45 / 100, 1]} mb={4}>
            <H3>Common Inquiries</H3>
            <ListItem onClick={() => setExampleText(exampleTexts[0])}>
              <Icon name="arrow_right" colorizeStroke size={28} />
              <span>Create a dashboard that visualizes our company data</span>
            </ListItem>
            <ListItem onClick={() => setExampleText(exampleTexts[1])}>
              <Icon name="arrow_right" colorizeStroke size={28} />
              <span>Build an interactive map to enhance my news article</span>
            </ListItem>
            <ListItem onClick={() => setExampleText(exampleTexts[2])}>
              <Icon name="arrow_right" colorizeStroke size={28} />
              <span>Extract and process data from a website</span>
            </ListItem>
          </Box>

          <ContactOptions width={[1, 1 / 2, 1]} ml={['0', 'auto', '0']}>
            <H3>Other Contact Options</H3>
            <ListItem>
              <Icon name="phone" colorizeStroke size={28} />
              +49 30 232 575 450
            </ListItem>
            <ListItem as="a" href="mailto:info@webkid.io">
              <Icon name="mail" colorizeStroke size={28} />
              info@webkid.io
            </ListItem>
            <ListItem
              as="a"
              href="https://twitter.com/webk1d"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="twitter_outline" colorizeStroke size={28} />
              @webk1d
            </ListItem>
          </ContactOptions>
        </Flex>
      </Flex>
    </Flex>
  );
};
